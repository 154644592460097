import { gql } from '@apollo/client';

export const getAccountFlows = gql`
  query accountFlows(
    $filter: AccountFlowFilterInput!
    $pagination: PaginationInput
    $searchBy: String
    $sortBy: AccountFlowSortInput
  ) {
    accountFlows(filter: $filter, pagination: $pagination, searchBy: $searchBy, sortBy: $sortBy) {
      itemsCount
      currentPage
      pageCount
      hits {
        readOnly
        flow {
          id
          alias
          title
          strategy
          typeId
          description
          settingData
        }
      }
    }
  }
`;

export const getBusinessTask = gql`
  query getTask($processId: String!, $assignee: [UserRole!]) {
    businessTask: getTask(processId: $processId, assignee: $assignee) {
      id
      name
      formKey
      assignee
    }
  }
`;

export const getWorkOrders = gql`
  query workOrderList(
    $filter: WorkOrderFilterInput!
    $sortBy: [WorkOrderSortInput!] = [{ field: updatedAt, order: DESC }]
    $page: Int!
    $itemsAmount: Int = 10
  ) {
    workOrderList(filter: $filter, sortBy: $sortBy, pagination: { page: $page, itemsAmount: $itemsAmount }) {
      hits {
        processId
        publicId
        accountId
        technicianUserId
        assignedUserId
        reAssignedUserId
        statusAlias
        statusTitle
        statusId
        flowId
        jobId
        isNotesShared
        flowAlias
        flowTitle
        strategyId
        strategyAlias
        strategyTitle
        requestedDate
        completedDate
        createdAt
        updatedAt
        flowData
        targetData
        rawTargetData
        integration
      }
      currentPage
      itemsCount
      pageCount
    }
  }
`;

export const getWOMessageList = gql`
  query workOrderMessageList($processId: String!, $page: Int = 1, $itemsAmount: Int = 30) {
    messageList: workOrderMessageList(
      processId: $processId
      pagination: { page: $page, itemsAmount: $itemsAmount }
    ) {
      hits {
        id
        processId
        sender
        body
        type
        attributes
        timestamp
      }
      currentPage
      itemsCount
      pageCount
    }
  }
`;

export const getDeviceWorkOrders = gql`
  query workOrderList(
    $filters: WorkOrderFilterInput!
    $sortBy: [WorkOrderSortInput!]
    $page: Int!
    $itemsAmount: Int = 10
  ) {
    workOrderList(filter: $filters, sortBy: $sortBy, pagination: { page: $page, itemsAmount: $itemsAmount }) {
      hits {
        workOrderNumber: jobId
        typeTitle
        createdAt
        completedDate
        flowData
        targetData
        strategyAlias
      }
      itemsCount
    }
  }
`;

export const getWorkOrderTypeOptions = gql`
  query workOrderTypes {
    types: workOrderTypes {
      value: id
      label: title
    }
  }
`;

export const workOrderStatuses = gql`
  query workOrderStatuses($filters: WorkOrderStatusFilterInput) {
    statuses: workOrderStatuses(filter: $filters) {
      id
      title
      alias
    }
  }
`;

export const fetchWODocuments = gql`
  query processDocuments($processId: String!) {
    documents: processDocuments(processId: $processId) {
      id
      file {
        id
        keyName
        fileName
        mimeType
      }
    }
  }
`;

export const fetchWOFileUrl = gql`
  query processDocumentFileUrl($processId: String!, $fileId: String!) {
    url: processDocumentFileUrl(processId: $processId, fileId: $fileId)
  }
`;

export const getWorkOrderSettings = gql`
  query workOrderListSettings($userId: Int!) {
    settings: workOrderListSettings(userId: $userId) {
      columnId
      isActive
      position
      title
      alias
    }
  }
`;

export const getWorkOrder = gql`
  query workOrder($jobId: String) {
    workOrder(jobId: $jobId) {
      processId: id
      woStatus: status {
        title
        alias
        id
      }
      strategy {
        id
        title
        alias
      }
      accountId
      publicId
      deviceId
      flowId
      flow {
        id
        alias
      }
      strategyId
      isNotesShared
      technicianUserId
      assignedUserId
      reAssignedUserId
      requestedDate
      completedDate
      flowData
      targetData
      integration
      updatedAt
      createdAt
      assignerUserId
    }
  }
`;

export const getTaskVariables = gql`
  query getTaskVariables($taskId: String!) {
    taskVariables: getTaskVariables(taskId: $taskId)
  }
`;

export const getWOCount = gql`
  query workOrderList($filter: WorkOrderFilterInput!) {
    workOrderList(filter: $filter) {
      itemsCount
    }
  }
`;

// Mutations
export const declineReassignedJob = gql`
  mutation declineReassignedJob($processId: String!) {
    declineReassignedJob(processId: $processId)
  }
`;

export const onUpdateWorkOrderSettings = gql`
  mutation updateWorkOrderListSettings($userId: Int!, $settings: [SettingColumnInput!]!) {
    updateWorkOrderListSettings(userId: $userId, settings: $settings) {
      columnId
      title
      alias
      isActive
      position
    }
  }
`;

export const createJob = gql`
  mutation createJob(
    $publicId: Int!
    $targetIds: [Int]!
    $strategyId: Int!
    $WOData: JSONObject
    $processData: JSONObject
  ) {
    process: createJob(
      publicId: $publicId
      strategyId: $strategyId
      targetIds: $targetIds
      WOData: $WOData
      processData: $processData
    ) {
      processId: id
      businessKey
      publicId
      target
      accountId
      strategyId
    }
  }
`;

export const startProcessForReport = gql`
  mutation startProcessForReport(
    $flowId: String!
    $businessKey: String!
    $publicId: Int!
    $targetIds: [Int]!
    $strategyId: Int!
  ) {
    process: startProcess(
      flowId: $flowId
      businessKey: $businessKey
      publicId: $publicId
      strategyId: $strategyId
      targetIds: $targetIds
    ) {
      processId: id
      businessKey
      publicId
      target
      accountId
      strategyId
    }
  }
`;

export const createJobByTechnician = gql`
  mutation createJobByTechnician(
    $publicId: Int!
    $targetIds: [Int]!
    $strategyId: Int!
    $facilityAccountId: Int!
    $WOData: JSONObject
    $processData: JSONObject
    $signature: String!
  ) {
    process: createJobByTechnician(
      publicId: $publicId
      targetIds: $targetIds
      strategyId: $strategyId
      facilityAccountId: $facilityAccountId
      WOData: $WOData
      processData: $processData
      signature: $signature
    ) {
      processId: id
      businessKey
      publicId
      target
      accountId
      strategyId
    }
  }
`;

export const completeTask = gql`
  mutation completeTask($taskId: String!, $formKey: FormKey, $variables: JSONObject) {
    completeTask(taskId: $taskId, formKey: $formKey, variables: $variables)
  }
`;

export const completeWOTask = gql`
  mutation completeWOTask(
    $processId: String!
    $taskId: String!
    $formKey: FormKey!
    $WOData: JSONObject
    $processData: JSONObject
  ) {
    completeWOTask(
      processId: $processId
      taskId: $taskId
      formKey: $formKey
      WOData: $WOData
      processData: $processData
    )
  }
`;

export const getWorkOrderNotes = gql`
  query GetWorkOrderNotes($filter: WorkOrderNotesListInput!, $page: Int!, $itemsAmount: Int = 10) {
    workOrderNoteList(filter: $filter, pagination: { page: $page, itemsAmount: $itemsAmount }) {
      hits {
        id
        message
        userId
        authorName
        completed
        createdAt
      }
    }
  }
`;

export const addWorkOrderNote = gql`
  mutation AddWorkOrderNote($processId: String!, $message: String, $authorName: String!) {
    addWorkOrderNote(processId: $processId, message: $message, authorName: $authorName) {
      id
      authorName
      message
      completed
      userId
      createdAt
    }
  }
`;

export const editWorkOrderNote = gql`
  mutation editWorkOrderNote($id: Int!, $message: String) {
    editWorkOrderNote(id: $id, message: $message) {
      id
      message
    }
  }
`;

export const completeWorkOrderNote = gql`
  mutation CompleteWorkOrderNote($id: Int!) {
    completeWorkOrderNote(id: $id) {
      id
      completed
    }
  }
`;

export const deleteWorkOrderNote = gql`
  mutation DeleteWorkOrderNote($id: Int!) {
    deleteWorkOrderNote(id: $id)
  }
`;

export const shareWorkOrderNotes = gql`
  mutation ShareWorkOrderNotes($processId: String!) {
    shareWorkOrderNotes(processId: $processId)
  }
`;

export const completeWOTaskWithStatus = gql`
  mutation completeWOTask(
    $processId: String!
    $taskId: String!
    $statusId: Int!
    $formKey: FormKey!
    $WOData: JSONObject
    $processData: JSONObject
  ) {
    updateWorkOrderStatus(processId: $processId, statusId: $statusId)
    completeWOTask(
      processId: $processId
      taskId: $taskId
      formKey: $formKey
      WOData: $WOData
      processData: $processData
    )
  }
`;

export const sendIntegrationMessageQuery = gql`
  mutation sendIntegrationMessage($processId: String!, $provider: ProviderType, $message: String!) {
    sendIntegrationMessage(processId: $processId, provider: $provider, message: $message)
  }
`;

export const getDepartmentsOptions = gql`
  query getDepartmentsOptions {
    departments {
      id
      refName
    }
  }
`;

export const getVendorsOptions = gql`
  query getVendorsOptions {
    vendors {
      id
      refName
    }
  }
`;

export const reassignWorkOrderToTechnician = gql`
  mutation reassignWorkOrderToTechnician(
    $processId: String!
    $signature: String!
    $userId: Int!
    $accountId: Int!
    $type: RepairerType!
    $email: String!
    $fullName: String!
    $phone: String
    $department: ReassigmentItemInput
    $vendor: ReassigmentItemInput
  ) {
    reassignWorkOrderToTechnician(
      processId: $processId
      signature: $signature
      userId: $userId
      accountId: $accountId
      type: $type
      email: $email
      fullName: $fullName
      phone: $phone
      department: $department
      vendor: $vendor
    )
  }
`;

export const editEstimatedCost = gql`
  mutation editEstimatedCost($processId: String!, $cost: Float!) {
    editEstimatedCost(processId: $processId, cost: $cost)
  }
`;

export const rescheduleAppointment = gql`
  mutation reschedule($processId: String!, $requestedDate: Int!, $arriveWindow: Int!) {
    reschedule(processId: $processId, requestedDate: $requestedDate, arriveWindow: $arriveWindow)
  }
`;

export const updateDeviceStatuses = gql`
  mutation updateDeviceStatuses($deviceIds: [Int!]) {
    updateDeviceStatuses(deviceIds: $deviceIds)
  }
`;

export const addAssignmentForFacility = gql`
  mutation addAssignmentForFacility(
    $accountId: Int!
    $facilityId: Int!
    $facilityName: String!
    $technician1: TechnicianShortInfoInput!
    $technician1Timeout: Int
    $technician2: TechnicianShortInfoInput
    $technician2Timeout: Int
    $technician3: TechnicianShortInfoInput
    $technician3Timeout: Int
    $technician4: TechnicianShortInfoInput
    $technician4Timeout: Int
    $technician5: TechnicianShortInfoInput
  ) {
    addAssignmentForFacility(
      accountId: $accountId
      facilityId: $facilityId
      facilityName: $facilityName
      technician1: $technician1
      technician1Timeout: $technician1Timeout
      technician2: $technician2
      technician2Timeout: $technician2Timeout
      technician3: $technician3
      technician3Timeout: $technician3Timeout
      technician4: $technician4
      technician4Timeout: $technician4Timeout
      technician5: $technician5
    ) {
      id
      accountId
      facilityId
      facilityName
      technician1 {
        id
        name
        email
        phone
      }
      technician1Timeout
      technician2 {
        id
        name
        email
        phone
      }
      technician2Timeout
      technician3 {
        id
        name
        email
        phone
      }
      technician3Timeout
      technician4 {
        id
        name
        email
        phone
      }
      technician4Timeout
      technician5 {
        id
        name
        email
        phone
      }
    }
  }
`;

export const updateAssignmentForFacility = gql`
  mutation updateAssignmentForFacility(
    $assignmentId: Int!
    $technician1: TechnicianShortInfoInput!
    $technician1Timeout: Int
    $technician2: TechnicianShortInfoInput
    $technician2Timeout: Int
    $technician3: TechnicianShortInfoInput
    $technician3Timeout: Int
    $technician4: TechnicianShortInfoInput
    $technician4Timeout: Int
    $technician5: TechnicianShortInfoInput
  ) {
    updateAssignmentForFacility(
      id: $assignmentId
      technician1: $technician1
      technician1Timeout: $technician1Timeout
      technician2: $technician2
      technician2Timeout: $technician2Timeout
      technician3: $technician3
      technician3Timeout: $technician3Timeout
      technician4: $technician4
      technician4Timeout: $technician4Timeout
      technician5: $technician5
    ) {
      id
      accountId
      facilityId
      facilityName
      technician1 {
        id
        name
        email
        phone
      }
      technician1Timeout
      technician2 {
        id
        name
        email
        phone
      }
      technician2Timeout
      technician3 {
        id
        name
        email
        phone
      }
      technician3Timeout
      technician4 {
        id
        name
        email
        phone
      }
      technician4Timeout
      technician5 {
        id
        name
        email
        phone
      }
    }
  }
`;

export const startProcessFromCompleted = gql(`
    mutation startProcessFromCompleted(
        $processId: String!
        $publicId: Int!
        $deviceStatus: String!
        $deviceId: Int!
        $typeId: Int!
        $priority: String!
        $jobStatusId: Int!
        $copyHistory: Boolean!
        $description: String!
    ) {
        startProcessFromCompleted(
            processId: $processId,
            publicId: $publicId,
            deviceStatus: $deviceStatus,
            deviceId: $deviceId,
            typeId: $typeId,
            priority: $priority,
            jobStatusId: $jobStatusId,
            copyHistory: $copyHistory,
            description: $description
        )
    }
`);
