import { format, fromUnixTime, isValid, parse } from 'date-fns';
import uuid from 'uuid';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import fromPairs from 'lodash/fromPairs';
import compose from 'lodash/fp/compose';
import reduce from 'lodash/fp/reduce';
import values from 'lodash/fp/values';
import {
  FIELD_NAME,
  FIELD_TYPE,
  FIELD_VALUE,
  FORM_FIELDS_REGEX,
  SYS_FIELDS_REGEX,
} from '@uptime/shared/constants/logs';
import { fromString, getLocalUnixTimestamp } from './date';

export const getFields = (variables) =>
  Object.keys(variables)
    .filter((key) => FORM_FIELDS_REGEX.test(key))
    .map((key) => ({
      name: key.split('_')[1],
      shouldDisableField: false,
      ...variables[key],
    }))
    .sort((a, b) =>
      (a.order || 'Z').localeCompare(b.order || 'Z', undefined, { numeric: true, sensitivity: 'base' })
    );

export const getSysFields = (variables) => {
  const sysFields = Object.keys(variables)
    .filter((key) => SYS_FIELDS_REGEX.test(key))
    .map((key) => [key.split('_')[1], variables[key]]);

  return fromPairs(sysFields);
};

export const prepareFormData = (variables, initials) => {
  const fields = getFields(variables);
  const sysFields = getSysFields(variables);

  const initialValues = fromPairs(
    fields.map(({ name, value, type }) => {
      if (type === FIELD_TYPE.NUMBER) return [name, parseInt(value, 10)];
      if (type === FIELD_TYPE.CHECKBOX) return [name, Boolean(value)];
      if (type === FIELD_TYPE.DATEPICKER) {
        if (value === FIELD_VALUE.TODAY) return [name, new Date()];
        if (value) return [name, new Date(value)];
      }
      if (type === FIELD_TYPE.TEXT) {
        if (name === FIELD_NAME.INITIALS) return [name, initials];
      }

      return [name, value];
    })
  );

  return {
    fields,
    sysFields: {
      ...sysFields,
      last: JSON.parse(get(sysFields, 'last', 'false')),
    },
    initialValues,
  };
};

export const getAccumulatedInitialValues = compose(
  reduce(
    (accum, { initialValues }) => ({
      ...accum,
      ...initialValues,
    }),
    {}
  ),
  values
);

export const getVariables = (data = {}) => {
  const images = [];

  return [
    Object.entries(data).reduce((accum, [key, value]) => {
      const isDate = value instanceof Date;
      const isImage = value instanceof File;
      const isBoolean = typeof value == 'boolean';

      if (isImage) {
        images.push({ key, file: value, id: uuid.v4() });
        return accum;
      }

      if (isBoolean) {
        return {
          ...accum,
          [key]: value ? 'Yes' : 'No',
        };
      }

      return {
        ...accum,
        [key]: isDate ? format(value, 'LL-dd-yyyy HH:mm') : value,
      };
    }, {}),
    images,
  ];
};

export const getLogFlowsOptions = (flows = []) =>
  flows
    .map(({ id, title, alias, strategy, readOnly }) => ({
      value: id,
      label: title,
      alias,
      strategy,
      readOnly,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const getCorrectedFields = (fields) =>
  fields.map((field) => ({
    ...field,
    shouldDisableField: get(field, 'decision', 'false') === 'true',
  }));

export const getLogs = (hits = []) =>
  hits.map(({ id, logData, flow, readData, createdAt }) => ({
    ...readData,
    ...logData,
    id,
    flowTitle: flow.title,
    createdAt: format(fromUnixTime(createdAt), 'LLL dd, yyyy'),
  }));

export const getExportLogs = (hits = []) =>
  hits.map(({ logData, readData }) => ({
    ...readData,
    ...logData,
  }));

export const getLogComplianceFilters = (filters = {}) =>
  Object.entries(filters)
    .filter(([, value]) => {
      if (isArray(value)) return !isEmpty(value);

      return Boolean(value);
    })
    .reduce(
      (accum, [key, value]) => ({
        ...accum,
        [key]: value,
      }),
      {}
    );

export const getLogValue = (rawValue) => {
  const isDate = isValid(parse(rawValue, 'MM-dd-yyyy HH:mm', new Date()));

  if (isDate) {
    // IOS Invalid time value, we have to parse rawValue first
    // as date string is not compatible with IOS new Date constructor
    return format(parse(rawValue, 'MM-dd-yyyy HH:mm', new Date()), 'LLL dd, yyyy');
  }

  return rawValue;
};

export const getLogCompliancePhantomFilters = (filters = {}) => {
  const toDate = filters.toDate && getLocalUnixTimestamp(filters.toDate);
  const fromDate = filters.fromDate && getLocalUnixTimestamp(filters.fromDate);

  return {
    ...filters,
    toDate,
    fromDate,
  };
};

export const getUniqSeries = (logs = []) =>
  uniq(
    flatten(
      logs.map((item) =>
        Object.entries(item)
          .filter(([key]) => key !== 'date')
          .map(([key]) => key)
      )
    )
  );

export const getLogComplianceURL = (facilityId, deviceId) => {
  if (!facilityId) return '/app/logs/compliance';

  return [
    `/app/logs/compliance?`,
    `facilityId=${facilityId}`,
    Boolean(deviceId) ? `&deviceId=${deviceId}` : '',
  ].join('');
};

export const hasPhantomLog = (flowIds = [], phantomFlow = {}) => flowIds.includes(phantomFlow.id);

export const getPhantomDataSource = (logs = [], controlType = '', baseLine = 4) =>
  logs.map((log) => {
    const todayDate = log.logData.date;
    return {
      x1: todayDate,
      y1: Number(log.logData[controlType]),
      x2: todayDate,
      y2: baseLine,
      baseLine,
    };
  });

export const phantomPlaceholder = (dataSource = []) => {
  return dataSource.length === 1
    ? [
        {
          x1: 'extreme_first',
          y1: 3,
          x2: 'extreme_first',
          y2: 4,
          baseLine: 4,
        },
        ...dataSource,
        {
          x1: 'extreme_last',
          y1: 3,
          x2: 'extreme_last',
          y2: 4,
          baseLine: 4,
        },
      ]
    : dataSource;
};

export const transformLogData = (logData) => {
  const acc = {
    EI: [],
    kPv: [],
    initials: [],
    filter: [],
    mAs: [],
    correctiveActionRequired: [],
  };

  return logData.reduce((acc, log) => {
    acc['EI'] = [...acc['EI'], log.logData.ei];
    acc['kPv'] = [...acc['kPv'], log.logData.kpv];
    acc['filter'] = [...acc['filter'], log.logData.filter];
    acc['mAs'] = [...acc['mAs'], log.logData.mas];
    acc['initials'] = [...acc['initials'], log.logData.initials];
    acc['correctiveActionRequired'] = [
      ...acc['correctiveActionRequired'],
      log.logData.correctiveActionRequired,
    ];
    return acc;
  }, acc);
};

export const getComplexWidgetSetup = (alias) => {
  const HEMOCUE_LIQUID_CONTROL_LIKE_SETUP = {
    top: ['Liquid Control: Level 1', 'Liquid Control: Level 2', 'Liquid Control: Level 3'],
    left: ['Lot #', 'Date Opened', 'Range', 'Expiration Date'],
    keys: [
      {
        id: 'Liquid Control: Level 1|Date Opened',
        getData: (row) => row.dateOpened1 && format(fromString(row.dateOpened1), 'LLL dd, yyyy'),
      },
      {
        id: 'Liquid Control: Level 1|Lot #',
        getData: (row) => row.kitLotNumberLevel1,
      },
      {
        id: 'Liquid Control: Level 1|Expiration Date',
        getData: (row) =>
          row.kitExpirationDateLevel1 && format(fromString(row.kitExpirationDateLevel1), 'LLL dd, yyyy'),
      },
      {
        id: 'Liquid Control: Level 1|Range',
        getData: (row) => row.rangeLevel1,
      },

      {
        id: 'Liquid Control: Level 2|Date Opened',
        getData: (row) => row.dateOpened2 && format(fromString(row.dateOpened2), 'LLL dd, yyyy'),
      },
      {
        id: 'Liquid Control: Level 2|Lot #',
        getData: (row) => row.kitLotNumberLevel2,
      },
      {
        id: 'Liquid Control: Level 2|Expiration Date',
        getData: (row) =>
          row.kitExpirationDateLevel2 && format(fromString(row.kitExpirationDateLevel2), 'LLL dd, yyyy'),
      },
      {
        id: 'Liquid Control: Level 2|Range',
        getData: (row) => row.rangeLevel2,
      },

      {
        id: 'Liquid Control: Level 3|Date Opened',
        getData: (row) => row.dateOpened3 && format(fromString(row.dateOpened3), 'LLL dd, yyyy'),
      },
      {
        id: 'Liquid Control: Level 3|Lot #',
        getData: (row) => row.kitLotNumberLevel3,
      },
      {
        id: 'Liquid Control: Level 3|Expiration Date',
        getData: (row) =>
          row.kitExpirationDateLevel3 && format(fromString(row.kitExpirationDateLevel3), 'LLL dd, yyyy'),
      },
      {
        id: 'Liquid Control: Level 3|Range',
        getData: (row) => row.rangeLevel3,
      },
    ],
  };

  const TWO_LEVEL_BOX_CONTROL_TWO_FIELDS_SETUP = {
    top: ['Box Control: Level 1', 'Box Control: Level 2'],
    left: ['Expiration Date', 'Lot #'],
    keys: [
      {
        id: 'Box Control: Level 1|Expiration Date',
        getData: (row) =>
          row.kitExpirationDateLevel1 && format(fromString(row.kitExpirationDateLevel1), 'LLL dd, yyyy'),
      },
      {
        id: 'Box Control: Level 1|Lot #',
        getData: (row) => row.kitLotNumberLevel1,
      },
      {
        id: 'Box Control: Level 2|Expiration Date',
        getData: (row) =>
          row.kitExpirationDateLevel2 && format(fromString(row.kitExpirationDateLevel2), 'LLL dd, yyyy'),
      },
      {
        id: 'Box Control: Level 2|Lot #',
        getData: (row) => row.kitLotNumberLevel2,
      },
    ],
  };

  const HEMOCUE_BOX_CONTROL_LEVEL3_LIKE_SETUP = {
    top: ['Box Control: Level 1', 'Box Control: Level 2', 'Box Control: Level 3'],
    left: ['Expiration Date', 'Lot #', 'Range'],
    keys: [
      {
        id: 'Box Control: Level 1|Lot #',
        getData: (row) => row.kitLotNumberLevel1,
      },
      {
        id: 'Box Control: Level 1|Expiration Date',
        getData: (row) =>
          row.kitExpirationDateLevel1 && format(fromString(row.kitExpirationDateLevel1), 'LLL dd, yyyy'),
      },
      {
        id: 'Box Control: Level 1|Range',
        getData: (row) => row.rangeLevel1,
      },
      {
        id: 'Box Control: Level 2|Lot #',
        getData: (row) => row.kitLotNumberLevel2,
      },
      {
        id: 'Box Control: Level 2|Expiration Date',
        getData: (row) =>
          row.kitExpirationDateLevel2 && format(fromString(row.kitExpirationDateLevel2), 'LLL dd, yyyy'),
      },
      {
        id: 'Box Control: Level 2|Range',
        getData: (row) => row.rangeLevel2,
      },
      {
        id: 'Box Control: Level 3|Lot #',
        getData: (row) => row.kitLotNumberLevel3,
      },
      {
        id: 'Box Control: Level 3|Expiration Date',
        getData: (row) =>
          row.kitExpirationDateLevel3 && format(fromString(row.kitExpirationDateLevel3), 'LLL dd, yyyy'),
      },
      {
        id: 'Box Control: Level 3|Range',
        getData: (row) => row.rangeLevel3,
      },
    ],
  };

  const HEMOCUE_HEMOGLOBIN_HIGHLOWCONTROL_LIKE_SETUP = {
    top: ['Box Control: Cuvette', 'Level: Low Control', 'Level: High Control'],
    left: ['Expiration Date', 'Lot #', 'Range'],
    keys: [
      {
        id: 'Box Control: Cuvette|Expiration Date',
        getData: (row) =>
          row.kitExpirationDateTestStrip &&
          format(fromString(row.kitExpirationDateTestStrip), 'LLL dd, yyyy'),
      },
      {
        id: 'Box Control: Cuvette|Lot #',
        getData: (row) => row.kitLotNumberTestStrip,
      },
      {
        id: 'Level: Low Control|Expiration Date',
        getData: (row) =>
          row.kitExpirationDateLevel1 && format(fromString(row.kitExpirationDateLevel1), 'LLL dd, yyyy'),
      },
      {
        id: 'Level: Low Control|Lot #',
        getData: (row) => row.kitLotNumberLevel1,
      },
      {
        id: 'Level: Low Control|Range',
        getData: (row) => row.rangeLevel1,
      },
      {
        id: 'Level: High Control|Expiration Date',
        getData: (row) =>
          row.kitExpirationDateLevel3 && format(fromString(row.kitExpirationDateLevel3), 'LLL dd, yyyy'),
      },
      {
        id: 'Level: High Control|Lot #',
        getData: (row) => row.kitLotNumberLevel3,
      },
      {
        id: 'Level: High Control|Range',
        getData: (row) => row.rangeLevel3,
      },
    ],
  };

  const MAPPER_BOX_CONTROL = {
    LipidCholestechPremierHealth: {
      top: ['Cholestech LDX'],
      left: [
        'Date Put into Use',
        'Acceptable Range',
        'Cholestech LDS - Serial #',
        'Lot # (Cassette)',
        'Expiration Date',
      ],
      keys: [
        {
          id: 'Cholestech LDX|Date Put into Use',
          getData: (row) => row.todaysDate && format(fromString(row.todaysDate), 'LLL dd, yyyy'),
        },
        {
          id: 'Cholestech LDX|Acceptable Range',
          getData: (row) => row.acceptableRange,
        },
        {
          id: 'Cholestech LDX|Cholestech LDS - Serial #',
          getData: (row) => row.serialNumber,
        },
        {
          id: 'Cholestech LDX|Lot # (Cassette)',
          getData: (row) => row.cassetteLotNumber,
        },
        {
          id: 'Cholestech LDX|Expiration Date',
          getData: (row) => row.expDate && format(fromString(row.expDate), 'LLL dd, yyyy'),
        },
      ],
    },
    HemocueGlucoseDaily3LevelControlML: HEMOCUE_BOX_CONTROL_LEVEL3_LIKE_SETUP,
    HemocueHemoglobin3LevelControlML: HEMOCUE_BOX_CONTROL_LEVEL3_LIKE_SETUP,

    HemocueGlucose2013LevelControlML: HEMOCUE_LIQUID_CONTROL_LIKE_SETUP,
    HemocueHB8013LevelControlML: HEMOCUE_LIQUID_CONTROL_LIKE_SETUP,

    HemoCuePremierHealth: {
      top: ['Box Control: Cuvette', 'Level: Low Control', 'Level: High Control'],
      left: [
        'Date Box Received into Clinic',
        'Date Put into Use',
        'Employee Initials',
        'Expiration Date',
        'Lot #',
        'Range',
      ],
      keys: [
        {
          id: 'Box Control: Cuvette|Date Box Received into Clinic',
          getData: (row) => row.receiveDate && format(fromString(row.receiveDate), 'LLL dd, yyyy'),
        },
        {
          id: 'Box Control: Cuvette|Expiration Date',
          getData: (row) =>
            row.kitExpirationDateTestStrip &&
            format(fromString(row.kitExpirationDateTestStrip), 'LLL dd, yyyy'),
        },
        {
          id: 'Box Control: Cuvette|Lot #',
          getData: (row) => row.kitLotNumberCuvette,
        },
        {
          id: 'Box Control: Cuvette|Date Put into Use',
          getData: (row) => row.useDateCuvette && format(fromString(row.useDateCuvette), 'LLL dd, yyyy'),
        },
        {
          id: 'Level: Low Control|Expiration Date',
          getData: (row) =>
            row.kitExpirationDateLevelLowControl &&
            format(fromString(row.kitExpirationDateLevelLowControl), 'LLL dd, yyyy'),
        },
        {
          id: 'Level: Low Control|Lot #',
          getData: (row) => row.kitLotNumberLevelLowControl,
        },
        {
          id: 'Level: Low Control|Range',
          getData: (row) => row.rangeLevelLowControl,
        },
        {
          id: 'Level: High Control|Expiration Date',
          getData: (row) =>
            row.kitExpirationDateLevelHighControl &&
            format(fromString(row.kitExpirationDateLevelHighControl), 'LLL dd, yyyy'),
        },
        {
          id: 'Level: High Control|Lot #',
          getData: (row) => row.kitLotNumberLevelHighControl,
        },
        {
          id: 'Level: High Control|Range',
          getData: (row) => row.rangeLevelHighControl,
        },
        {
          id: 'Box Control: Cuvette|Employee Initials',
          getData: (row) => row.initials,
        },
      ],
    },

    HemoCueHemoglobin201HighLowControlML: HEMOCUE_HEMOGLOBIN_HIGHLOWCONTROL_LIKE_SETUP,
    HemoCueHemoglobin801HighLowControlML: HEMOCUE_HEMOGLOBIN_HIGHLOWCONTROL_LIKE_SETUP,

    ClintekUrinalysisML: TWO_LEVEL_BOX_CONTROL_TWO_FIELDS_SETUP,
    ClintekMicroalbumin2ControlML: TWO_LEVEL_BOX_CONTROL_TWO_FIELDS_SETUP,

    ClintekProteinGlucoseControlML: {
      top: ['Control: Level 1', 'Control: Level 2'],
      left: ['Expiration Date', 'Lot #'],
      keys: [
        {
          id: 'Control: Level 1|Expiration Date',
          getData: (row) =>
            row.kitExpirationDateLevel1 && format(fromString(row.kitExpirationDateLevel1), 'LLL dd, yyyy'),
        },
        {
          id: 'Control: Level 1|Lot #',
          getData: (row) => row.kitLotNumberLevel1,
        },
        {
          id: 'Control: Level 2|Expiration Date',
          getData: (row) =>
            row.kitExpirationDateLevel2 && format(fromString(row.kitExpirationDateLevel2), 'LLL dd, yyyy'),
        },
        {
          id: 'Control: Level 2|Lot #',
          getData: (row) => row.kitLotNumberLevel2,
        },
      ],
    },
    AbbottAfinionHBa1cExternalQCLog: {
      top: ['Control C I', 'Control C II'],
      left: ['Expiration Date', 'Lot #', 'Range'],
      keys: [
        {
          id: 'Control C I|Expiration Date',
          getData: (row) =>
            row.kitExpirationDateCI && format(fromString(row.kitExpirationDateCI), 'LLL dd, yyyy'),
        },
        {
          id: 'Control C I|Lot #',
          getData: (row) => row.kitLotNumberCI,
        },
        {
          id: 'Control C I|Range',
          getData: (row) => row.kitRangeCI,
        },
        {
          id: 'Control C II|Expiration Date',
          getData: (row) =>
            row.kitExpirationDateCII && format(fromString(row.kitExpirationDateCII), 'LLL dd, yyyy'),
        },
        {
          id: 'Control C II|Lot #',
          getData: (row) => row.kitLotNumberCII,
        },
        {
          id: 'Control C II|Range',
          getData: (row) => row.kitRangeCII,
        },
      ],
    },

    HemoglobinA1CML: {
      top: ['Box Control: Cartridges', 'Box Control: Level 1', 'Box Control: Level 2'],
      left: ['Expiration Date', 'Lot #', 'Range'],
      keys: [
        {
          id: 'Box Control: Cartridges|Expiration Date',
          getData: (row) =>
            row.kitExpirationDateTestStrip &&
            format(fromString(row.kitExpirationDateTestStrip), 'LLL dd, yyyy'),
        },
        {
          id: 'Box Control: Cartridges|Lot #',
          getData: (row) => row.kitLotNumberTestStrip,
        },
        {
          id: 'Box Control: Level 1|Expiration Date',
          getData: (row) =>
            row.kitExpirationDateLevel1 && format(fromString(row.kitExpirationDateLevel1), 'LLL dd, yyyy'),
        },
        {
          id: 'Box Control: Level 1|Lot #',
          getData: (row) => row.kitLotNumberLevel1,
        },
        {
          id: 'Box Control: Level 1|Range',
          getData: (row) => row.rangeLevel1,
        },
        {
          id: 'Box Control: Level 2|Expiration Date',
          getData: (row) =>
            row.kitExpirationDateLevel3 && format(fromString(row.kitExpirationDateLevel3), 'LLL dd, yyyy'),
        },
        {
          id: 'Box Control: Level 2|Lot #',
          getData: (row) => row.kitLotNumberLevel3,
        },
        {
          id: 'Box Control: Level 2|Range',
          getData: (row) => row.rangeLevel3,
        },
      ],
    },

    HemoCueEone: {
      top: ['Box Control: Cuvette', 'Level: Low Control', 'Level: High Control'],
      left: ['Date Box Received into Clinic', 'Employee Initials', 'Expiration Date', 'Lot #', 'Range'],
      keys: [
        {
          id: 'Box Control: Cuvette|Date Box Received into Clinic',
          getData: (row) => row.receiveDate && format(fromString(row.receiveDate), 'LLL dd, yyyy'),
        },
        {
          id: 'Box Control: Cuvette|Expiration Date',
          getData: (row) =>
            row.kitExpirationDateTestStrip &&
            format(fromString(row.kitExpirationDateTestStrip), 'LLL dd, yyyy'),
        },
        {
          id: 'Box Control: Cuvette|Lot #',
          getData: (row) => row.kitLotNumberCuvette,
        },
        {
          id: 'Level: Low Control|Expiration Date',
          getData: (row) =>
            row.kitExpirationDateLevelLowControl &&
            format(fromString(row.kitExpirationDateLevelLowControl), 'LLL dd, yyyy'),
        },
        {
          id: 'Level: Low Control|Lot #',
          getData: (row) => row.kitLotNumberLevelLowControl,
        },
        {
          id: 'Level: Low Control|Range',
          getData: (row) => row.rangeLevelLowControl,
        },
        {
          id: 'Level: High Control|Expiration Date',
          getData: (row) =>
            row.kitExpirationDateLevelHighControl &&
            format(fromString(row.kitExpirationDateLevelHighControl), 'LLL dd, yyyy'),
        },
        {
          id: 'Level: High Control|Lot #',
          getData: (row) => row.kitLotNumberLevelHighControl,
        },
        {
          id: 'Level: High Control|Range',
          getData: (row) => row.rangeLevelHighControl,
        },
        {
          id: 'Box Control: Cuvette|Employee Initials',
          getData: (row) => row.initials,
        },
      ],
    },
    HemoCue: {
      top: ['Control Level 1', 'Control Level 2', 'Control Level 3'],
      left: ['Lot #', 'Expected Range', 'Expiration Date'],
      keys: [
        {
          id: 'Control Level 1|Expiration Date',
          getData: (row) => row.lotExpiration1 && format(fromString(row.lotExpiration1), 'LLL dd, yyyy'),
        },
        {
          id: 'Control Level 1|Lot #',
          getData: (row) => row.lotNumber1,
        },
        {
          id: 'Control Level 1|Expected Range',
          getData: (row) => row.userange1,
        },
        {
          id: 'Control Level 2|Expiration Date',
          getData: (row) => row.lotExpiration2 && format(fromString(row.lotExpiration2), 'LLL dd, yyyy'),
        },
        {
          id: 'Control Level 2|Lot #',
          getData: (row) => row.lotNumber2,
        },
        {
          id: 'Control Level 2|Expected Range',
          getData: (row) => row.userange2,
        },
        {
          id: 'Control Level 3|Expiration Date',
          getData: (row) => row.lotExpiration3 && format(fromString(row.lotExpiration3), 'LLL dd, yyyy'),
        },
        {
          id: 'Control Level 3|Lot #',
          getData: (row) => row.lotNumber3,
        },
        {
          id: 'Control Level 3|Expected Range',
          getData: (row) => row.userange3,
        },
      ],
    },
    HemoGlobinA1C: {
      top: ['Normal Control', 'Abnormal Control'],
      left: ['Lot #', 'Expected Range', 'Expiration Date'],
      keys: [
        {
          id: 'Normal Control|Expiration Date',
          getData: (row) => row.lotExpiration1 && format(fromString(row.lotExpiration1), 'LLL dd, yyyy'),
        },
        {
          id: 'Normal Control|Lot #',
          getData: (row) => row.lotNumber1,
        },
        {
          id: 'Normal Control|Expected Range',
          getData: (row) => row.userange1,
        },
        {
          id: 'Abnormal Control|Expiration Date',
          getData: (row) => row.lotExpiration2 && format(fromString(row.lotExpiration2), 'LLL dd, yyyy'),
        },
        {
          id: 'Abnormal Control|Lot #',
          getData: (row) => row.lotNumber2,
        },
        {
          id: 'Abnormal Control|Expected Range',
          getData: (row) => row.userange2,
        },
      ],
    },
    HemoglobinA1CControllog: {
      top: ['Test Strip', 'C1', 'C2'],
      left: [
        'Date Put Into Use',
        'Lot #',
        'Expiration Date',
        'Date Box Received Into Clinic',
        'Range',
        'Employee Initials',
      ],
      keys: [
        {
          id: 'Test Strip|Date Put Into Use',
          getData: (row) => row.receiveDate && format(fromString(row.receiveDate), 'LLL dd, yyyy'),
        },
        {
          id: 'Test Strip|Lot #',
          getData: (row) => row.kitLotNumberTestStrip,
        },
        {
          id: 'Test Strip|Expiration Date',
          getData: (row) =>
            row.kitExpirationDateTestStrip &&
            format(fromString(row.kitExpirationDateTestStrip), 'LLL dd, yyyy'),
        },
        {
          id: 'Test Strip|Date Box Received Into Clinic',
          getData: (row) => row.todaysDate && format(fromString(row.todaysDate), 'LLL dd, yyyy'),
        },
        {
          id: 'C1|Lot #',
          getData: (row) => row.kitLotNumberLevel1,
        },
        {
          id: 'C1|Expiration Date',
          getData: (row) =>
            row.kitExpirationDateLevel1 && format(fromString(row.kitExpirationDateLevel1), 'LLL dd, yyyy'),
        },
        {
          id: 'C1|Range',
          getData: (row) => row.rangeLevel1,
        },
        {
          id: 'C2|Lot#',
          getData: (row) => row.kitLotNumberLevel3,
        },
        {
          id: 'C2|Expiration Date',
          getData: (row) =>
            row.kitExpirationDateLevel3 && format(fromString(row.kitExpirationDateLevel3), 'LLL dd, yyyy'),
        },
        {
          id: 'C2|Range',
          getData: (row) => row.rangeLevel3,
        },
        {
          id: 'Test Strip|Employee Initials',
          getData: (row) => row.initials,
        },
      ],
    },
    Glucometer3Level: {
      top: ['Test Strip', 'Level 1 Control', 'Level 2 Control', 'Level 3 Control'],
      left: ['Date Put Into Use', 'Lot #', 'Expiration Date', 'Range', 'Employee Initials'],
      keys: [
        {
          id: 'Test Strip|Date Put Into Use',
          getData: (row) => row.receiveDate && format(fromString(row.receiveDate), 'LLL dd, yyyy'),
        },
        {
          id: 'Test Strip|Lot #',
          getData: (row) => row.kitLotNumberTestStrip,
        },
        {
          id: 'Test Strip|Expiration Date',
          getData: (row) =>
            row.kitExpirationDateTestStrip &&
            format(fromString(row.kitExpirationDateTestStrip), 'LLL dd, yyyy'),
        },
        {
          id: 'Level 1 Control|Lot #',
          getData: (row) => row.kitLotNumberLevel1,
        },
        {
          id: 'Level 1 Control|Expiration Date',
          getData: (row) =>
            row.kitExpirationDateLevel1 && format(fromString(row.kitExpirationDateLevel1), 'LLL dd, yyyy'),
        },
        {
          id: 'Level 1 Control|Range',
          getData: (row) => row.kitRangeLevel1,
        },
        {
          id: 'Level 2 Control|Lot #',
          getData: (row) => row.kitLotNumberLevel2,
        },
        {
          id: 'Level 2 Control|Expiration Date',
          getData: (row) =>
            row.kitExpirationDateLevel2 && format(fromString(row.kitExpirationDateLevel2), 'LLL dd, yyyy'),
        },
        {
          id: 'Level 2 Control|Range',
          getData: (row) => row.kitRangeLevel2,
        },
        {
          id: 'Level 3 Control|Lot #',
          getData: (row) => row.kitLotNumberLevel3,
        },
        {
          id: 'Level 3 Control|Expiration Date',
          getData: (row) =>
            row.kitExpirationDateLevel3 && format(fromString(row.kitExpirationDateLevel3), 'LLL dd, yyyy'),
        },
        {
          id: 'Level 3 Control|Range',
          getData: (row) => row.rangeLevel3,
        },
        {
          id: 'Test Strip|Employee Initials',
          getData: (row) => row.initials,
        },
      ],
    },
  };

  const defaultSetup = {
    top: ['Test Strip', 'Level 1 Control', 'Level 3 Control'],
    left: [
      'Date Put Into Use',
      'Lot #',
      'Expiration Date',
      'Date Box Received Into Clinic',
      'Range',
      'Employee Initials',
    ],
    keys: [
      {
        id: 'Test Strip|Date Put Into Use',
        getData: (row) => row.receiveDate && format(fromString(row.receiveDate), 'LLL dd, yyyy'),
      },
      {
        id: 'Test Strip|Lot #',
        getData: (row) => row.kitLotNumberTestStrip,
      },
      {
        id: 'Test Strip|Expiration Date',
        getData: (row) =>
          row.kitExpirationDateTestStrip &&
          format(fromString(row.kitExpirationDateTestStrip), 'LLL dd, yyyy'),
      },
      {
        id: 'Test Strip|Date Box Received Into Clinic',
        getData: (row) => row.todaysDate && format(fromString(row.todaysDate), 'LLL dd, yyyy'),
      },
      {
        id: 'Level 1 Control|Lot #',
        getData: (row) => row.kitLotNumberLevel1,
      },
      {
        id: 'Level 1 Control|Expiration Date',
        getData: (row) =>
          row.kitExpirationDateLevel1 && format(fromString(row.kitExpirationDateLevel1), 'LLL dd, yyyy'),
      },
      {
        id: 'Level 1 Control|Range',
        getData: (row) => row.rangeLevel1,
      },
      {
        id: 'Level 3 Control|Lot#',
        getData: (row) => row.kitLotNumberLevel3,
      },
      {
        id: 'Level 3 Control|Expiration Date',
        getData: (row) =>
          row.kitExpirationDateLevel3 && format(fromString(row.kitExpirationDateLevel3), 'LLL dd, yyyy'),
      },
      {
        id: 'Level 3 Control|Range',
        getData: (row) => row.rangeLevel3,
      },
      {
        id: 'Test Strip|Employee Initials',
        getData: (row) => row.initials,
      },
    ],
  };

  const setup = MAPPER_BOX_CONTROL[alias];

  return setup || defaultSetup;
};

export const getKeyValuePairsWidgetSetup = (alias) => {
  const MAPPER_BOX_CONTROL = {
    MonitorQC: {
      keys: [
        {
          key: 'ltserialnumber',
          label: 'LT Serial Number',
          getData: (row) => row.ltserialnumber || '-',
        },
        {
          key: 'rtserialnumber',
          label: 'RT Serial Number',
          getData: (row) => row.rtserialnumber || '-',
        },
      ],
    },
    DipstickUrinalysis: {
      keys: [
        {
          label: 'Glucose',
          normal: (row) => row.glucose1,
          abnormal: (row) => row.glucose2,
        },
        {
          label: 'Bilirubin',
          normal: (row) => row.biliruben1,
          abnormal: (row) => row.biliruben2,
        },
        {
          label: 'Ketones',
          normal: (row) => row.ketones1,
          abnormal: (row) => row.ketones2,
        },
        {
          label: 'Specific Gravity',
          normal: (row) => row.specificgravity1,
          abnormal: (row) => row.specificgravity2,
        },
        {
          label: 'Blood',
          normal: (row) => row.blood1,
          abnormal: (row) => row.blood2,
        },
        {
          label: 'pH',
          normal: (row) => row.ph1,
          abnormal: (row) => row.ph2,
        },
        {
          label: 'Protein',
          normal: (row) => row.protein1,
          abnormal: (row) => row.protein2,
        },
        {
          label: 'Urobilinogen',
          normal: (row) => row.urobilinogen1,
          abnormal: (row) => row.urobilinogen2,
        },
        {
          label: 'Nitrite',
          normal: (row) => row.nitrate1,
          abnormal: (row) => row.nitrate2,
        },
        {
          label: 'Leukocytes',
          normal: (row) => row.leukocytes1,
          abnormal: (row) => row.leukocytes2,
        },
      ],
    },
  };

  return MAPPER_BOX_CONTROL[alias];
};

export const getIsResolut = (flowIds, resolutFlows) => {
  const { resolut2DFlow = {}, resolut3DFlow = {} } = resolutFlows;
  if (!flowIds) return false;
  const ids = typeof flowIds === 'string' ? [flowIds] : flowIds;

  return ids.some((flow) => flow === resolut2DFlow.id || flow === resolut3DFlow.id);
};

export const getIsHomogeneity = (flowIds, homogeneityFlows) => {
  if (!flowIds || !homogeneityFlows) return false;

  const ids = typeof flowIds === 'string' ? [flowIds] : flowIds;
  return ids.some((flowId) => flowId === homogeneityFlows.id);
};

export const transformFlowData = (accountFlows) => {
  if (!accountFlows) return [];
  return (
    accountFlows?.hits?.map((row) => {
      return { ...row, ...row.flow };
    }) || []
  );
};

export const adjustValues = (fields = [], logData = {}) =>
  fields.reduce((accum, { type, name }) => {
    if (type === FIELD_TYPE.CHECKBOX) {
      return {
        ...accum,
        [name]: logData[name] === 'Yes',
      };
    }

    return {
      ...accum,
      [name]: logData[name],
    };
  }, {});
